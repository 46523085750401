import React from 'react';
import BalanceText from '@/components/BalanceText/BalanceText';

type HeadingProps = {
    smallHeading?: string; heading?: string; as?: React.ElementType; className?: string; children?: React.ReactNode; style?: React.CSSProperties; balanced?: boolean;
}

export const MainHeading = ({ heading = null, className = '', children = null, ...props }) => {
    /*
     Large heading: 64px, 70px line height (1.1), weight 600, color #000000
     */
    // return (<h1 {...props} className={`whitespace-pre-line font-semibold font-Inter text-[#000000] text-[48px] md:text-[54] lg:text-[64px] mx-auto text-center  ${className}`}
    //             style={{ lineHeight: '70px', marginBlockEnd: '0px' }}
    return (
        <h1
            className={`
            select-none
            mx-auto 

            whitespace-pre-line

            font-Inter 
            font-semibold 

  
            text-center

            text-[24px] 
            sm:text-[30px] 
            md:text-[36px] 
            lg:text-[42px] 
            xl:text-[54px] 
            2xl:text-[64px]
        
            bg-purple-100-
            xs:bg-green-600-
            sm:bg-orange-300-
            md:bg-red-300-
            lg:bg-green-300-
            xl:bg-yellow-300-
            2xl:bg-blue-300-
        
            leading-[1.2]
          
        ${className}
    `}
            style={{ marginBlockEnd: '0', marginBlockStart: '0' }}
        >
            <>
                {heading ?? children}
            </>
        </h1>
    );
};

export const LargeHeading = ({ as = 'h2', heading = null, className = '', children = null, balanced = false, style = null }: HeadingProps) => {
    const As = as;
    /*
     Large heading: 48.2378px, 56px line height, 1.16 line height ratio
                    weight 500, color #131313
     */
    return (
        <As className={`
            select-none

            whitespace-normal
            no-whitespace-pre-line
            
            lg:whitespace-pre-line
            xl:whitespace-pre-line
            2xl:whitespace-pre-line
        
            mx-auto 
            my-2             
            
            text-center
            lg:text-left

            font-medium 
            font-Inter 

            text-[#131313]


            leading-[1.26]
            lg:leading-[1.16]
            
            text-[24px]
            xs:text-2xl
            sm:text-[30px]
            lg:text-[40px]
            xl:text-[44px]
            2xl:text-[48px]
        
            sm:bg-orange-200-
            md:bg-red-200-
            lg:bg-green-200-
            xl:bg-yellow-200-
            2xl:bg-blue-200-

            ${className}
        `}
        style={{ marginBlockEnd: '0px', ...style }}

        >
            <>
                {balanced ? (<BalanceText>{heading ?? children}</BalanceText>) : (heading ?? children)}
            </>
        </As>
    );
    // textShadow: '0px 5px 2px rgba(0, 0, 0, 0.05)'
    // return (<h2 className='font-semibold text-center lg:text-left text-[#18181B] text-3xl md:text-6xl mt-3 mb-0 leading-normal'>
    //     {heading}
    // </h2>);
};

export const MediumHeading = ({ heading = null, className = '', children = null }) => {
    /*
        H2 - Medium heading: 32.46px, 38.45px line height, 1.18 line height ratio
     */
    return (
        <h3 className={`
            ${className} 
    
            select-none
    
            m-0 
            p-0 
    
            font-medium 
            font-Inter 
    
            text-left 
            text-[#131313]
            leading-[1.5] 
            
            text-2xl
            md:text-2xl 
            lg:text-[29px] 
            xl:text-[31px] 
        `}
        >
            <>{heading ?? children}</>
        </h3>
    );
};

export const Heading2 = ({ heading = null, className = '', children = null, style = null }) => {
    /*
        font-size: 16px
     */
    return (
        <h2
            className={`
                ${className}
                font-medium
                md:font-semibold
                font-Inter
                text-[#131313]
                
                text-center 
                md:text-left
                 
                text-[24px]
                md:text-[28px]
                lg:text-[29px]
                xl:text-[34px]
                2xl:text-[36px]
                
                leading-[1.3]
                my-0
            `}
            style={style}
        >
            {heading ?? children}
        </h2>
    );
};

export const Heading3 = ({ heading = null, className = '', children = null, style = null }) => {
    /*
        font-size: 16px
     */
    return (
        <h2
            className={`
                ${className}
                font-medium
                md:font-semibold
                
                font-Poppins
                text-[#ffffff]
                
                text-center 
                md:text-left
                 
                text-[22px]
                md:text-[24px]
                lg:text-[26px]
                xl:text-[28px]
                2xl:text-[30px]
                
                leading-[1.3]
                my-0
            `}
            style={style}
        >
            {heading ?? children}
        </h2>
    );
};

export const SubHeading = ({ heading = null, children = null, className = '' }) => {
    /*
        smaller heading: 28px, 36px line height, 1.29 line height ratio, weight 600, color #131313
     */
    return (
        <h4 className={`
            select-none

            m-0 
            p-0 

            font-Inter
            font-semibold 

            text-left 
            text-[#131313]

            leading-[1.29] 

            text-[22px]
            sm:text-[23px]
            md:text-[24px]
            lg:text-[25px]
            xl:text-[26px]
            2xl:text-[27px]
            
            ${className}
             
        `}>
            {heading ?? children}
        </h4>
    );
};

export const SmallHeading = ({ smallHeading = null, as = 'h6', className = '', children = null, ...props }: HeadingProps) => {
    const As = as;
    /*
         As as React.ElementType
         font-size: 16px
     */
    return (
        <As className={`
            select-none
            
            text-center
            lg:text-left
    
            my-0
            
            tracking-wide
             
            text-base
            sm:text-lg
            md:text-xl
            lg:text-base 
            xl:text-lg 
            2xl:text-xl
            
            text-[#6B7280]
            -bg-red-100
            
            ${className}
        `}>
            {smallHeading ?? children}
        </As>
    );
};

export const BlackHeading = ({ heading = null, className = '', children = null }) => {
    /*
        font-weight: 900;
        font-size: 40px;
        line-height: 50px;
        Large heading: 40px, 50px line height, 1.25 line height ratio
        weight 900,
        weight 500, color #131313
     */
    return (
        <h4 className={`
        select-none
        whitespace-pre-line 

        mx-auto 
        my-4
        
        font-black
        font-Inter 
         
        leading-[1.25]

        text-[24px]
        sm:text-[28px]
        md:text-[32px]
        lg:text-[32px]
        xl:text-[36px]
        2xl:text-[40px]
        
        sm:bg-orange-200-
        md:bg-red-200-
        lg:bg-green-200-
        xl:bg-yellow-200-
        2xl:bg-blue-200-
         
        ${className}
        `}
        style={{ marginBlockEnd: '0px' }}
        >
            {heading ?? children}
        </h4>
    );
    // textShadow: '0px 5px 2px rgba(0, 0, 0, 0.05)'
    // return (<h2 className='font-semibold text-center lg:text-left text-[#18181B] text-3xl md:text-6xl mt-3 mb-0 leading-normal'>
    //     {heading}
    // </h2>);
};


export const SectionHeading = ({ text = null, className = '', children = null }) => {
    /*
        font-weight: 900;
        font-size: 40px;
        line-height: 50px;
        Large heading: 40px, 50px line height, 1.25 line height ratio
        weight 900,
        weight 500, color #131313
     */
    // className='lg: z-20 relative text-[41.21px]'
    //
    return (
        <h4 className={`
        select-none
        mx-auto 
        my-4
                
        font-extrabold
        font-Inter 
         
        leading-[1.25]

        text-center
        
        text-[24px]
        sm:text-[28px]
        md:text-[32px]
        lg:text-[32px]
        xl:text-[36px]
        2xl:text-[40px]
       
        ${className}
        `}
        style={{ marginBlockEnd: '0px' }}
        >
            {text ?? children}
        </h4>
    );
};

// text-[#6B7280]

/*
    split text on newlines, and create an unbreakable div for each line
 */
export const FixedLines = ({ text }) => {
    // convert the lines to newlines
    // split the text into lines on the newlines
    const lines = text?.split('\n') ?? [];
    return lines.map((line, index) => {
        return (
            <span key={line} className='whitespace-normal md:whitespace-nowrap sm:block mr-2 sm:mr-0'>
                {line}
            </span>
        );
    });
};

export default LargeHeading;
